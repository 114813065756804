<template>
  <div id="pc-container">
    <div id="pc-mask"></div>
    <div id="pc-main" class="center">
      <!-- left logo -->
      <a id="pc-left-logo" href="https://www.moartron.com/" target="_blank">
        <p style="font-size: 53px">MOAR TRON</p>
        <p style="font-size: 33px">DROPPING ON TRON</p>
      </a>
      <!-- right link -->
      <div id="pc-right-icon" class="center">
        <a href="https://www.moartron.com/" target="_blank" class="logo" style="margin-right: 24px">Home</a>
        <a
          href="https://apenft.io/collections/moartron
"
          target="_blank"
          class="logo market-logo center"
          style="margin-right: 24px"
          ><img style="width: 14px; margin-right: 6px" src="@/assets/pc/market-logo.png" />Market</a
        >
        <a href="https://t.me/MoarTron" target="_blank"
          ><img style="width: 32px; margin-right: 24px" src="@/assets/pc/telegram.png"
        /></a>
        <a href="https://twitter.com/moartron" target="_blank"
          ><img style="width: 32px" src="@/assets/pc/twitter.png"
        /></a>
      </div>

      <div id="pc-center">
        <div id="pc-moto" class="center">
          <img src="@/assets/pc/img.png" style="width: 260px; margin-top: -20px" />
        </div>
        <div id="pc-center-right" class="center">
          <stage-23 />
        </div>
      </div>

      <!-- bottom -->
      <div id="pc-bottom" class="center">
        <img src="@/assets/pc/trx.png" style="width: 20px; opacity: 0.62; margin-right: 10px" />

        5555 MOAR DROPPING ON TRON
      </div>
    </div>
  </div>
</template>
<script>
import { countdownMixin } from '../mixin';
import Stage23 from './stage23.vue';

export default {
  name: 'mint-pc',
  mixins: [countdownMixin],
  components: {
    stage23: Stage23,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="less">
@font-face {
  font-family: bebas;
  src: url('~@/assets/bebas.ttf');
}
#pc-container {
  width: 100vw;
  min-width: 1080px;
  height: 100vh;
  background-image: url('~@/assets/pc/back.png');
  background-size: cover;
  position: relative;
}
#pc-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.65);
}
#pc-main {
  position: relative;
  width: 100%;
  height: 100%;
}
#pc-left-logo {
  position: absolute;
  left: 30px;
  top: 40px;
  font-weight: bold;
  color: #fff;
  font-family: bebas;
  line-height: 1;
}
#pc-right-icon {
  position: absolute;
  top: 50px;
  right: 40px;
  .logo {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
  }
  .market-logo {
    background: #cd4743;
    border-radius: 50px;
    width: 100px;
    height: 30px;
  }
}
#pc-center {
  display: flex;
  padding-bottom: 20px;
  padding-top: 20px;
  #pc-moto {
  }
  #pc-center-right {
    width: 300px;
    margin-left: 40px;
    justify-content: flex-start;
  }
}
#pc-bottom {
  position: absolute;
  width: 100%;
  bottom: 25px;
  color: #9e9c9b;
  /* font-weight: normal; */
  font-size: 14px;
  letter-spacing: 3.5px;
}

#pc-set {
  position: absolute;
  bottom: 70px;
  border: 1px dashed;
  padding: 0 5px;
  font-size: 13px;
}
</style>
